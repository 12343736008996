import React, { useState } from "react";
import data from "./data";
import { useEffect } from "react";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
const Slider = () => {
  const [images, setImages] = useState(data);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const lastIndex = images.length - 1;
    if (index < 0) {
      setIndex(lastIndex);
    }
    if (index > lastIndex) {
      setIndex(0);
    }
  }, [index, images]);
  useEffect(() => {
    let slider = setInterval(() => {
      setIndex(index + 1);
    }, 3000);
    return () => clearInterval(slider);
  }, [index]);
  return (
    <section className="section-lucrari">
      <div className="section-center">
        {images.map((item, imgIndex) => {
          const { id, img } = item;
          let pozition = "nextSlide";
          if (imgIndex === index) {
            pozition = "activeSlide";
          }
          if (
            imgIndex === index - 1 ||
            (index === 0 && imgIndex === images.length)
          ) {
            pozition = "lastSlide";
          }
          return (
            <article key={id} className={`article-view ${pozition}`}>
              <img src={img} alt="car" className="img-view" />
            </article>
          );
        })}
        <button className="prev" onClick={() => setIndex(index - 1)}>
          <FiChevronLeft />
        </button>
        <button className="next" onClick={() => setIndex(index + 1)}>
          <FiChevronRight />
        </button>
      </div>
    </section>
  );
};

export default Slider;
