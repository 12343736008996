import React from "react";
import { useState, useEffect } from "react";
import { FaPhoneAlt } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";
import { FaFacebookSquare } from "react-icons/fa";

const Home = () => {
  const [isContactsVisible, setContactsVisible] = useState(false);
  const [isMapVisible, setMapVisible] = useState(false);
  useEffect(() => {
    const timer1 = setTimeout(() => {
      setContactsVisible(true);
    }, 1000);

    const timer2 = setTimeout(() => {
      setMapVisible(true);
    }, 1500);

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
    };
  }, []);

  return (
    <div className="container">
      <section className="hero">
        <h1>Darius & Andrei</h1>
        <h2> Detailing Auto </h2>
        <h4>
          Vrei ca masina ta sa straluceasca atat pe exterior cat si in interior
          , nu ezita sa ne contactezi.
        </h4>
      </section>

      <div className={`contacts ${isContactsVisible ? "visible" : ""}`}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d7745.078453462581!2d22.589293453699565!3d47.68287610408387!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDfCsDQwJzU2LjAiTiAyMsKwMzUnMzkuMyJF!5e0!3m2!1sro!2sro!4v1701353907969!5m2!1sro!2sro"
          width="600"
          height="450"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
          className={`map ${isMapVisible ? "visible" : ""}`}
        ></iframe>
        <div className="social">
          <h2>Contacts:</h2>
          <div className="phone">
            <FaPhoneAlt />
            <a href="tel:0770109423">0770 109 423</a>
          </div>
          <div className="email">
            <IoIosMail />
            <a href="mailto:cicalaudarius@gmail.com">cicalaudarius@gmail.com</a>
          </div>
          <div className="facebook">
            <FaFacebookSquare />
            <a href="https://www.facebook.com/darius.alexandru.7359">
              Darius Cicalau
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
