import { NavLink } from "react-router-dom";

const StyleNavbar = () => {
  return (
    <nav className="navbar">
      <NavLink to="/">Home</NavLink>
      <NavLink to="preturi">Preturi</NavLink>
    </nav>
  );
};

export default StyleNavbar;
