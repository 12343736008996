import car1 from "../image/car1.webp";
import car2 from "../image/car2.webp";
import car3 from "../image/car3.webp";
import car4 from "../image/car4.webp";
import car5 from "../image/car5.webp";
import car6 from "../image/car6.webp";
import car7 from "../image/car7.webp";
import car8 from "../image/car8.webp";
import car9 from "../image/car9.webp";
import car10 from "../image/car10.webp";
import car11 from "../image/car11.webp";
import car12 from "../image/car12.webp";
import car13 from "../image/car13.webp";
import car14 from "../image/car14.webp";
import car15 from "../image/car15.webp";
import car16 from "../image/car16.webp";
import car17 from "../image/car17.webp";
import car18 from "../image/car18.webp";
import car19 from "../image/car19.webp";
import car20 from "../image/car20.webp";
import car21 from "../image/car21.webp";
import car22 from "../image/car22.webp";
import car23 from "../image/car23.webp";
import car24 from "../image/car24.webp";
import car25 from "../image/car25.webp";
import car26 from "../image/car26.webp";
import car27 from "../image/car27.webp";
import car28 from "../image/car28.webp";
import car29 from "../image/car29.webp";
import car30 from "../image/car30.webp";
import car31 from "../image/car31.webp";
import car32 from "../image/car32.webp";
import car33 from "../image/car33.webp";
import car34 from "../image/car34.webp";
import car35 from "../image/car35.webp";
import car36 from "../image/car36.webp";
import car37 from "../image/car37.webp";
import car38 from "../image/car38.webp";
import car39 from "../image/car39.webp";
import car40 from "../image/car40.webp";
import car41 from "../image/car41.webp";
import car42 from "../image/car42.webp";
import car43 from "../image/car43.webp";
import car44 from "../image/car44.webp";
import car45 from "../image/car45.webp";
import car46 from "../image/car46.webp";
import car47 from "../image/car47.webp";
import car48 from "../image/car48.webp";
import car49 from "../image/car49.webp";
import car50 from "../image/car50.webp";
import car51 from "../image/car51.webp";
import car52 from "../image/car52.webp";
import car53 from "../image/car53.webp";
import car54 from "../image/car54.webp";
import car55 from "../image/car55.webp";
import car56 from "../image/car56.webp";
import car57 from "../image/car57.webp";
import car58 from "../image/car58.webp";
import car59 from "../image/car59.webp";

const data = [
  {
    id: 1,
    img: car1,
  },
  {
    id: 2,
    img: car2,
  },
  {
    id: 3,
    img: car3,
  },
  {
    id: 4,
    img: car4,
  },
  {
    id: 5,
    img: car5,
  },
  {
    id: 6,
    img: car6,
  },
  {
    id: 7,
    img: car7,
  },
  {
    id: 8,
    img: car8,
  },
  {
    id: 9,
    img: car9,
  },
  {
    id: 10,
    img: car10,
  },
  {
    id: 11,
    img: car11,
  },
  {
    id: 12,
    img: car12,
  },
  {
    id: 13,
    img: car13,
  },
  {
    id: 14,
    img: car14,
  },
  {
    id: 15,
    img: car15,
  },
  {
    id: 16,
    img: car16,
  },
  {
    id: 17,
    img: car17,
  },
  {
    id: 18,
    img: car18,
  },
  {
    id: 19,
    img: car19,
  },
  {
    id: 20,
    img: car20,
  },
  {
    id: 21,
    img: car21,
  },
  {
    id: 22,
    img: car22,
  },
  {
    id: 23,
    img: car23,
  },
  {
    id: 24,
    img: car24,
  },
  {
    id: 25,
    img: car25,
  },
  {
    id: 26,
    img: car26,
  },
  {
    id: 27,
    img: car27,
  },
  {
    id: 28,
    img: car28,
  },
  {
    id: 29,
    img: car29,
  },
  {
    id: 30,
    img: car30,
  },
  {
    id: 31,
    img: car31,
  },
  {
    id: 32,
    img: car32,
  },
  {
    id: 33,
    img: car33,
  },
  {
    id: 34,
    img: car34,
  },
  {
    id: 35,
    img: car35,
  },
  {
    id: 36,
    img: car36,
  },
  {
    id: 37,
    img: car37,
  },
  {
    id: 38,
    img: car38,
  },
  {
    id: 39,
    img: car39,
  },
  {
    id: 40,
    img: car40,
  },
  {
    id: 41,
    img: car41,
  },
  {
    id: 42,
    img: car42,
  },
  {
    id: 43,
    img: car43,
  },
  {
    id: 44,
    img: car44,
  },
  {
    id: 45,
    img: car45,
  },
  {
    id: 46,
    img: car46,
  },
  {
    id: 47,
    img: car47,
  },
  {
    id: 48,
    img: car48,
  },
  {
    id: 49,
    img: car49,
  },
  {
    id: 50,
    img: car50,
  },
  {
    id: 51,
    img: car51,
  },
  {
    id: 52,
    img: car52,
  },
  {
    id: 53,
    img: car53,
  },
  {
    id: 54,
    img: car54,
  },
  {
    id: 55,
    img: car55,
  },
  {
    id: 56,
    img: car56,
  },
  {
    id: 57,
    img: car57,
  },
  {
    id: 58,
    img: car58,
  },
  {
    id: 59,
    img: car59,
  },
];

export default data;
