import React from "react";
import Slider from "./Slider";

const Preturi = () => {
  return (
    <section className="price-section">
      <h2 className="text-h1">Preturi</h2>
      <p className="text-p">
        Pretul difera in functie de culoarea si starea masinii
      </p>
      <div className="prices">
        {" "}
        <article className="card">
          <p>Faruri</p>
          <p>200 lei</p>
        </article>
        <article className="card">
          <p>Stopuri</p>
          <p>100 lei</p>
        </article>
        <article className="card">
          <p>Polish integral, 3 straturi polish + ceara carnauba</p>

          <p>1000 - 1200 lei</p>
        </article>
        <article className="card">
          <p>Polish integral + ceara ceramica</p>
          <p>1500-1700 lei</p>
        </article>
        <article className="card">
          <p>Curatare tapiterie (chimic)</p>
          <p>300 lei</p>
        </article>
        <article className="card">
          <p>Curatare tapiterie + mocheta</p>
          <p>400 lei</p>
        </article>
        <article className="card">
          <p>Curatare tapiterie + mocheta + plastice</p>
          <p>500 lei</p>
        </article>
        <article className="card">
          <p>Detailing interior Auto (special)</p>
          <p>700 lei</p>
        </article>
        <article className="card">
          <p>Curatare piele + tratament </p>
          <p>750 lei</p>
        </article>
      </div>
      <Slider />
    </section>
  );
};

export default Preturi;
