import React from "react";
import { Link, Outlet } from "react-router-dom";

import StyleNavbar from "../components/StyleNavbar";

const SharedLayout = () => {
  return (
    <>
      <StyleNavbar />
      <Outlet />
      <footer className="footer">© Adresa : jud Satu Mare , Moftinu mic</footer>
    </>
  );
};
export default SharedLayout;
